<template>
  <resume />
</template>

<script>
import resume from "@/views/resume";
export default {
  name: "app",
  components: {
    resume,
  },
};
</script>