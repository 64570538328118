<template>
  <section class="resume-content p-3 p-lg-5 d-flex align-items-center" id="skills">
    <div class="w-100">
      <h2 class="mb-5 text-darker">Skills</h2>
      <div class="subheading mb-3">Programming Languages &amp; Tools</div>
      <ul class="list-inline dev-icons">
        <li class="list-inline-item" title="HTML5">
          <i class="fab fa-html5"></i>
        </li>
        <li class="list-inline-item" title="CSS3">
          <i class="fab fa-css3-alt"></i>
        </li>
        <li class="list-inline-item" title="SASS">
          <i class="fab fa-sass"></i>
        </li>
        <li class="list-inline-item" title="Javascript">
          <i class="fab fa-js-square"></i>
        </li>
        <li class="list-inline-item" title="Wordpress">
          <i class="fab fa-wordpress"></i>
        </li>
        <li class="list-inline-item" title="Bootstrap">
          <i class="fab fa-bootstrap"></i>
        </li>
        <li class="list-inline-item" title="Python">
          <i class="fab fa-python"></i>
        </li>
        <li class="list-inline-item" title="ReactJs">
          <i class="fab fa-node"></i>
        </li>
        <li class="list-inline-item" title="VueJs">
          <i class="fab fa-vuejs"></i>
        </li>
        <li class="list-inline-item" title="ReactJs">
          <i class="fab fa-react"></i>
        </li>
        <li class="list-inline-item" title="Bash">
          <i class="fa fa-terminal h1"></i>
        </li>
      </ul>
      <div class="subheading mb-3">Workflow</div>
      <ul class="fa-ul mb-0">
        <li>
          <i class="fa-li fa fa-check"></i> Backend Development &bull;
          <strong>Express | Fastify | FastAPI | Flask | Django</strong>
        </li>
        <li>
          <i class="fa-li fa fa-check"></i> Database &bull;
          <strong>PostgreSQL | MongoDB | mySQL | MSSQL | SQLite</strong>
        </li>
        <li>
          <i class="fa-li fa fa-check"></i> Single Page Applications | Frontend
          &bull;
          <strong>ReactJs | VueJS</strong>
        </li>
        <li>
          <i class="fa-li fa fa-check"></i> Scripting & Automation &bull;
          <strong>Bash | Python</strong>
        </li>
        <li>
          <i class="fa-li fa fa-check"></i> UI/UX | Graphic Designing | Logos
          &bull;
          <strong>Figma | Adobe Illustrator</strong>
        </li>
        <li>
          <i class="fa-li fa fa-check"></i> Agile Development Tools
          &bull;
          <strong>Jira | Teamwork | Trello | Notion</strong>
        </li>
      </ul>
    </div>
  </section>
</template>