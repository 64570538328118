<template>
  <section class="resume-content p-3 p-lg-5 d-flex align-items-center" id="experiences">
    <div class="w-100">
      <h2 class="mb-5 text-darker">Experiences &amp; Certificates</h2>      
      <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-4">
        <div class="resume-content">
          <h3 class="mb-5">Experiences</h3>
          <h4 class="mb-0">Associate Graphic Designer</h4>
          <div class="subheading mb-3 text-darker">Eagle Eye Pvt. Ltd</div>
          <p class="font-weight-bold">March 2019 - June 2020</p>
        </div>
      </div>

      <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-4">
        <div class="resume-content">
          <h4 class="mb-0">Software Engineer</h4>
          <div class="subheading mb-3 text-darker">Bottle Technology Pvt. Ltd</div>
          <p class="font-weight-bold">July 2021 - January 2024</p>
        </div>
      </div>
      
      <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-4">
        <div class="resume-content">
          <h4 class="mb-0">Software Engineer</h4>
          <div class="subheading mb-3 text-darker">Maitri Services Pvt. Ltd.</div>
          <p class="font-weight-bold">March 2024 - Present</p>
        </div>
      </div>
      
      <h3 class="mb-4">Certificates</h3>
      <ul class="fa-ul mb-0">
        <li>
          <span class="font-weight-bold">
            <i class="fa-li fa fa-certificate text-darker"></i>
            Automate The Boring Stuffs With Python Programming
          </span>
        </li>
      </ul>
    </div>
  </section>
</template>