<template>
  <section class="resume-content p-3 p-lg-5 d-flex align-items-center" id="interests">
    <div class="w-100">
      <h2 class="mb-5 text-darker">Interests</h2>
      <p>
        Apart from generating these great ideas, I also enjoy Machine Learning &
        AI stuffs. Most of my time, I love being outdoors. I enjoy hanging out
        with my friends & acquaintances. I was keen on drawing & stuffs, but
        stopped doing that lately.
      </p>
      <p class="mb-0">
        When forced indoors, I enjoy reading books, follow some comedy & sci-fi
        genre movies and television shows, I constantly treat myself with dishes
        I coould make, If I'm not seen doing these things as told, I may be
        found spending on the web, hovering my mouse over latest technology
        advancements or building some stuffs.
      </p>
    </div>
  </section>
</template>