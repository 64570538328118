<template>
  <section class="resume-content p-3 p-lg-5 d-flex align-items-center" id="about">
    <h1 class="d-none m-0 p-0">
      Saroj Belbase | FullStack Developer | Nepali Python Developer | Nepali NodeJs Developer | Software Engineer
    </h1>
    <h2 class="d-none m-0 p-0">
      Saroj Belbase is a full-stack developer known for his work with Python and Node.js. His portfolio showcases a variety of projects, including an automated bot that scrapes news and investment opportunities in Nepal, a collection of smart utilities and helper functions, a Telegram bot for Nepal Banda updates, a browser extension for a distraction-free Nepali date, and a Flask app for checking numbers across Nepal's carriers. Although there is no recent activity listed for this period, his work reflects a diverse range of skills and interests in the tech industry.
    </h2>
    <div class="w-100">
      <p class="normal mb-1 text-darker">Hi, I am</p>
      <h1 class="display-1 font-weight-bold mb-0">
        Saroj
        <span class="text-darker">Belbase</span>
        &
      </h1>
      <div class="normal mb-4">
        <p class="mb-0">
          <span class="mr-1">I seek to contribute to this world with my</span>
          <span class="text-darker mx-1">algorithms</span>
          <span>and</span>
          <span class="text-darker mx-1">abstractions.</span>
        </p>
        <p class="text-modern mt-4">FullStack Developer &middot; Python & NodeJs</p>
      </div>

      <div class="icons">
        <a href="https://github.com/sarojbelbase">
          <i class="fab fa-github"></i>
        </a>
        <a href="https://behance.net/sarojbelbase">
          <i class="fab fa-behance"></i>
        </a>
        <a href="https://linkedin.com/in/sarojbelbase">
          <i class="fab fa-linkedin-in"></i>
        </a>
        <a href="https://instagram.com/whoisevensaroj">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://twitter.com/whoisevensaroj">
          <i class="fab fa-twitter"></i>
        </a>
      </div>
    </div>
  </section>
</template>