<template>
  <section class="resume-content p-3 p-lg-5 d-flex align-items-center" id="education">
    <div class="w-100">
      <h2 class="mb-5 text-darker">Education</h2>
      <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="resume-content">
          <h3 class="mb-0">Tribhuwan University</h3>
          <div class="subheading mb-3 text-darker">Bachelor</div>
          <p class="font-weight-bold">Computer Science & Information Technology</p>
        </div>
        <div class="resume-date text-md-right">
          <span class="text-darker font-weight-bold">2016 - 2021</span>
        </div>
      </div>
      <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="resume-content">
          <h3 class="mb-0">Adarsh Higher Secondary School</h3>
          <div class="subheading mb-3 text-darker">Intermediate</div>
          <p class="font-weight-bold">Computer Science</p>
        </div>
        <div class="resume-date text-md-right">
          <span class="text-darker font-weight-bold">2014 - 2016</span>
        </div>
      </div>
      <div class="resume-item d-flex flex-column flex-md-row justify-content-between">
        <div class="resume-content">
          <h3 class="mb-0">Lotus Higher Secondary School</h3>
          <div class="subheading mb-3 text-darker">High School</div>
          <p class="font-weight-bold">English Medium</p>
        </div>
        <div class="resume-date text-md-right">
          <span class="text-darker font-weight-bold">2002 - 2014</span>
        </div>
      </div>
    </div>
  </section>
</template>